import { ValidationController } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
import { LogManager, autoinject } from 'aurelia-framework';
import { ValidationControllerFactory } from 'aurelia-validation';
import { AlertService } from '../../../services/util/Alert';
import { ValidatedRole } from '../../../types/Roles';
import { bindable } from 'aurelia-framework';
import { RoleScope } from '@wsb_dev/datafi-shared/lib/types/Roles';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

const log = LogManager.getLogger('dfp:admin');

export interface PermissionType {
    type: string,
    value: string,
}

export interface PermissionList {
    name: string;
    label: string;
    disabled?: Record<string, boolean>;
    permission?: PermissionType[];
    /**
     * set if the permission type is an application level permission only
     */
    app?: boolean;
}
@autoinject
export class AdminEditPermissions {
    role: ValidatedRole;
    controller: ValidationController;

    scopes = [
        'app',
        'program',
    ]
    selectedScopes: string[] = [];

    @bindable permissionType: PermissionType[] = [
        { type: 'Edit', value: 'edit' },
        { type: 'Read', value: 'read' },
        { type: 'Remove', value: 'remove' },
        { type: 'Create', value: 'create' },
    ]

    @bindable adminChecked = false;

    @bindable permissionList: PermissionList[] = [
        { name: 'api/v1/form-submissions', label: 'Form Submissions (datafi pro forms)', disabled: {}, permission: [] },
        { name: 'api/v1/files', label: 'Files', disabled: {}, permission: [] },
        { name: 'api/v1/geocode', label: 'Geocoding', disabled: {}, permission: [], app: true },
        { name: 'api/v1/jobs', label: 'Jobs', disabled: {}, permission: [] },
        { name: 'api/v1/organizations', label: 'Organizations', disabled: {}, permission: [] },
        { name: 'api/v1/programs', label: 'Programs', disabled: {}, permission: [] },
        { name: 'api/v1/programs-asset-types', label: 'Program Asset Types', disabled: {}, permission: [] },
        { name: 'api/v1/programs-assets', label: 'Program Assets', disabled: {}, permission: [] },
        { name: 'api/v1/programs-configs', label: 'Program Configuration', disabled: {}, permission: [] },
        { name: 'api/v1/programs-secrets', label: 'Program Secrets', disabled: {}, permission: [] },
        { name: 'api/v1/programs-surveys', label: 'Program Surveys', disabled: {}, permission: [] },
        { name: 'api/v1/programs-users-roles', label: 'Program User Location/Roles', disabled: {}, permission: [] },
        { name: 'api/v1/programs-webhooks', label: 'Program Webhooks', disabled: {}, permission: [] },
        { name: 'api/v1/projects', label: 'Projects', disabled: {}, permission: [] },
        { name: 'api/v1/projects-files', label: 'Project Files', disabled: {}, permission: [] },
        { name: 'api/v2/surveys-media', label: 'Survey Media', disabled: {}, permission: [] },
        { name: 'api/v2/surveys', label: 'Surveys (Kobo)', disabled: {}, permission: [] },
        { name: 'api/v1/tokens', label: 'Tokens', disabled: {}, permission: [], app: true },
        { name: 'api/v1/roles', label: 'Roles', disabled: {}, permission: [], app: true },
        { name: 'api/v1/users', label: 'Users', disabled: {}, permission: [], app: true },
        { name: 'api/v1/users-roles', label: 'User Roles', disabled: {}, permission: [], app: true },
    ]

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private dialogController: DialogController,
        private alerts: AlertService,
        private api: DatafiProAPI,
    ) {
        this.role = new ValidatedRole({});
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    activate(role: ValidatedRole) {
        this.role = new ValidatedRole(role);

        if (this.role.scopes) {
            this.selectedScopes = this.role.scopes;
        }
        if (this.role.permissions) {
            this.populatePermissions(this.role);
        }
    }

    populatePermissions(role) {
        const incomingPermissions = [];
        for (const [key, value] of Object.entries(role.permissions)) {
            (key === '*') ? this.adminChecked = true : incomingPermissions.push([key, Object.keys(value)]);
        }

        this.permissionList.forEach((item) => {
            for (let i = 0; i < incomingPermissions.length; i++) {
                if (incomingPermissions[i][0] === item.name) {
                    this.permissionType.forEach((perm) => {
                        for (let j = 0; j < incomingPermissions[i][1].length; j++) {
                            if (incomingPermissions[i][1][j] === perm.value) {
                                item.permission.push(perm);
                            }
                        }
                    });
                }
            }
        });
    }

    validateSelection() {
        if (!this.adminChecked) {
            this.permissionList.forEach((item) => {
                this.permissionType.forEach((perm) => item.disabled[perm.value] = false);
            });
        } else {
            this.permissionList.forEach((item) => {
                item.permission = []; // uncheck all checkboxes
                this.permissionType.forEach((perm) => item.disabled[perm.value] = true);
            });
        }
    }

    parsePermissions() {
        const obj = {};
        let permissions = {};
        if (!this.adminChecked) {
            this.permissionList.forEach((item) => {
                if (item.permission.length === 1) {
                    // If only 1 permission type selected
                    item.permission.forEach((perm) => {
                        Object.assign(obj, { [item.name]: { [perm.value]: 1 } });
                    });
                } else if (item.permission.length > 1) {
                    // If more than 1 permission type selected
                    item.permission.forEach((perm) => {
                        Object.assign(permissions, { [perm.value]: 1 });
                    });
                    Object.assign(obj, { [item.name]: permissions });
                    permissions = {};
                }
            });
        } else {
            Object.assign(obj, { '*': { '*': '*' } });
        }
        return obj;
    }

    saveRole(role: ValidatedRole) {
        role.permissions = this.parsePermissions();

        return this.controller.validate({
            object: role,
        }).then((result) => {
            role.scopes = this.selectedScopes as RoleScope[];
            if (!result.valid) {
                throw new Error('Form is not valid');
            }
            if (!role.id) {
                return this.api.roles.create(role);
            }

            return this.api.roles.update(role.id, role);
        }).then((role) => {
            this.alerts.create({
                label: 'Role saved',
                dismissable: true,
            });
            this.role = new ValidatedRole({});
            this.dialogController.ok(role);
        }).catch((e) => {
            log.error(e);
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        });
    }
}
