import { ValidationController } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
import { LogManager, autoinject } from 'aurelia-framework';
import { ValidationControllerFactory } from 'aurelia-validation';
import { AlertService } from '../../../services/util/Alert';
import { ValidatedRole } from '../../../types/Roles';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

const log = LogManager.getLogger('dfp:admin');

@autoinject
export class AdminEditRole {

    role: ValidatedRole;
    controller: ValidationController;

    scopes = [
        'app',
        'program',
    ]

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private dialogController: DialogController,
        private alerts: AlertService,
        private api: DatafiProAPI,
    ) {
        this.role = new ValidatedRole({});
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    activate(role: ValidatedRole) {
        this.role = new ValidatedRole(role);
    }

    saveRole(role: ValidatedRole) {
        return this.controller.validate({
            object: role,
        }).then((result) => {
            if (!result.valid) {
                throw new Error('Form is not valid');
            }
            if (!role.id) {
                return this.api.roles.create(role);
            }
            return this.api.roles.update(role.id, role);
        }).then((role) => {
            this.alerts.create({
                label: 'Role saved',
                dismissable: true,
            });
            this.role = new ValidatedRole({});
            this.dialogController.ok(role);
        }).catch((e) => {
            log.error(e);
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        });
    }
}
