import { ValidationController } from 'aurelia-validation';
import { LogManager, autoinject } from 'aurelia-framework';
import { ValidationControllerFactory } from 'aurelia-validation';
import { ValidatedUser } from '../../../types/Users';
import { ValidatedRole } from '../../../types/Roles';
import { DialogController } from 'aurelia-dialog';
import { AlertService } from '../../../services/util/Alert';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ValidatedOrganization } from '../../../types/Organizations';
import { Paginated } from '@feathersjs/feathers';

const log = LogManager.getLogger('dfp:admin');

@autoinject
export class AdminEditUser {
    controller: ValidationController
    user: ValidatedUser;
    organizations: ValidatedOrganization[];
    roles: ValidatedRole[];
    globalRoleId: number;

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private dialogController: DialogController,
        private alerts: AlertService,
        private api: DatafiProAPI,
        organizations: ValidatedOrganization[],
        roles: ValidatedRole[],
    ) {
        this.user = new ValidatedUser({}, this.api.files);
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    async activate(user: any) {
        this.user = new ValidatedUser(user, this.api.files);
        this.globalRoleId = user.roles[0]?.role_id;
        await this.api.organizations.find({query: {$limit: 50}}).then((result: ValidatedOrganization) => this.organizations = result.data.map((org) => new ValidatedOrganization(org)));
        await this.api.roles.find({
            query: { scopes: { $contains: '["app"]' }, $limit: 50 },
        }).then((result: ValidatedRole) => this.roles = result.data.map((role) => new ValidatedRole(role)));
    }

    saveUser(user: ValidatedUser, createNewUser?: boolean): Promise<void> {
        return this.controller.validate({
            object: user,
        }).then(async (result) => {
            if (!result.valid) {
                throw new Error('Form is not valid');
            }
            const role = this.roles.find((role) => role.id === this.globalRoleId);

            if (!user.id) {
                const newUser = await this.api.users.create(user);
                if (role) {
                    const newRole = await this.api.usersRoles.create({
                        user_id: newUser.id,
                        role_id: this.globalRoleId,
                    });
                    newUser.roles = [{...newRole, role}];
                }
                return newUser;
            }

            const existingUserRole = user.roles?.find((r) => r.role_id === this.globalRoleId);
            if (existingUserRole) { return this.api.users.patch(user.id, user);}

            // delete existing role
            if (user.roles?.length) {
                await Promise.all(user.roles?.map((role) => {
                    if (!role?.id) { return; }
                    return this.api.usersRoles.remove(role.id);
                }));
            }
            // create new role
            if (role) {
                const newRole = await this.api.usersRoles.create({
                    user_id: user.id,
                    role_id: this.globalRoleId,
                });
                const patchedUser = await this.api.users.patch(user.id, user);
                patchedUser.roles = [{...newRole, role}];
                return patchedUser;
            }

        }).then((user) => {
            this.alerts.create({
                label: 'User saved',
                dismissable: true,
            });
            this.user = new ValidatedUser({}, this.api.files);
            this.globalRoleId = undefined;
            if(!createNewUser){
                this.dialogController.ok(user);
            }
        }).catch((e) => {
            log.error(e);
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        });
    }
}
