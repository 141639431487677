import { ValidationController } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
import { LogManager, autoinject } from 'aurelia-framework';
import { ValidationControllerFactory } from 'aurelia-validation';
import { AlertService } from '../../../services/util/Alert';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ValidatedOrganization } from '../../../types/Organizations';

const log = LogManager.getLogger('dfp:admin');

@autoinject
export class AdminEditOrganization {

    organization: ValidatedOrganization;
    controller: ValidationController;

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private dialogController: DialogController,
        private alerts: AlertService,
        private api: DatafiProAPI,
    ) {
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    activate(organization){
        this.organization = new ValidatedOrganization(organization);
    }

    saveOrganization(organization: ValidatedOrganization) {
        return this.controller.validate({
            object: organization,
        }).then((result) => {
            if (!result.valid) {
                throw new Error('Form is not valid');
            }
            if (!organization.id) {
                return this.api.organizations.create(organization);
            }
            return this.api.organizations.update(organization.id, organization);
        }).then((organization) => {
            this.alerts.create({
                label: 'Organization saved',
                dismissable: true,
            });
            this.dialogController.ok(organization);
        }).catch((e) => {
            log.error(e);
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        });
    }
}
